const textoGeneralMamografia = `
    <span style="color: red;"><b>MENSAJE DE PREPARACIÓN:</b></span>
    <br>
    <b>1.</b>	No utilice desodorante, polvos de talco, ni ningún tipo de loción o crema del cuello hacia abajo el día de la prueba, pues impide la realización del examen. <br>
    <b>2.</b>	Asista preferiblemente en  ropa cómoda, no llevar accesorios metálicos (pendientes, pulseras, etcétera) pues impide la realización del examen. <br>
    <b>3.</b>	Si cuenta con estudios anteriores realizados a las mamas como mamografías, ecografías de seno o biopsias de seno, debe  presentarlos el día del examen tanto la lectura como las imágenes, debido a que son de utilidad para la interpretación del estudio.
`;



module.exports = {
    textoGeneralMamografia
}